:root {
  --primary-color: #61dafb;
}

#root {
  height: 100%;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.header {
  background: #20232a;

  .container {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: space-between;

    .logo {
      font-size: 26px;
      font-weight: 700;
      color: var(--primary-color);

      .version {
        font-size: 14px;
        color: #999;
        margin-left: 7px;
      }
    }

    .search {
      width: 50%;

      input {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        background: #f3f6f9;
        font-size: 16px;
        padding: 0 15px;
      }
    }

    .user-actions {
      display: flex;

      .user-btn {
        margin-left: 10px;
        height: 40px;
        background: var(--primary-color);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }

  }
}

.main-content {
  display: flex;
  height: calc(100% - 60px);

  .sidebar {
    width: 300px;
    min-width: 300px;
    border-right: 1px solid #ddd;
    padding: 15px 15px 15px 0;

    nav {
      margin-bottom: 30px;

      h3 {
        font-size: 20px;
        font-weight: 500;
        padding-left: 15px;
        margin-bottom: 15px;
        border-left: 3px solid var(--primary-color);
      }

      .menu {
        --bgColor: #f6f7fa;
        --textColor: var(--primary-color);
        a {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          height: 37px;
          padding: 0 15px;
          font-size: 16px;
          border-radius: 4px;
          color: #333;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            background: #f6f7fa;
            color: var(--primary-color);
          }

          &.active {
            background: var(--bgColor);
            color: var(--textColor);
          }
        }
      }
    }
  }

  .content {
    flex: 1;
    overflow: auto;
    padding: 20px 0 20px 20px;

    .category-info {
      background: var(--bgColor);
      padding: 25px;
      color: #fff;
      margin-bottom: 25px;

      h3 {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 600;
      }

      .description {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    .category-sections {
      li {
        margin-bottom: 10px;

        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
          border-radius: 3px;
          background: #f7f7f7;
          color: #444;
          font-size: 16px;

          .category {
            font-size: 14px;
            opacity: .4;
          }
        }
      }
    }

  }

}